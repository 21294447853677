.alert{
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    &.success{
        color: darken($success-color, 25%);
        background-color: lighten($success-color, 30%);
        border-color: lighten($success-color, 30%);
    }
    &.error{
        color: darken($danger-color, 5%);
        background-color: lighten($danger-color, 25%);
        border-color: lighten($danger-color, 25%);
    }
}