@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,700italic,400italic);

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

// html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
//   margin: 0;
//   padding: 0;
//   border: 0;
//   font-size: 100%;
//   font: inherit;
//   vertical-align: baseline;
// }

// /* HTML5 display-role reset for older browsers */

// article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
//   display: block;
// }

// body {
//   padding: 25px;
//   line-height: 1;
// }

// ol, ul {
//   list-style: none;
// }

// blockquote, q {
//   quotes: none;
// }

// blockquote {
//   &:before, &:after {
//     content: '';
//     content: none;
//   }
// }

// q {
//   &:before, &:after {
//     content: '';
//     content: none;
//   }
// }

// table {
//   border-collapse: collapse;
//   border-spacing: 0;
// }

// * {
//   -webkit-box-sizing: border-box;
//   -moz-box-sizing: border-box;
//   box-sizing: border-box;
// }

// /**
//  * For modern browsers
//  * 1. The space content is one way to avoid an Opera bug when the
//  *    contenteditable attribute is included anywhere else in the document.
//  *    Otherwise it causes space to appear at the top and bottom of elements
//  *    that are clearfixed.
//  * 2. The use of `table` rather than `block` is only necessary if using
//  *    `:before` to contain the top-margins of child elements.
//  */

// .cf {
//   &:before {
//     content: " ";

//     /* 1 */
//     display: table;

//     /* 2 */
//   }

//   &:after {
//     content: " ";

//     /* 1 */
//     display: table;

//     /* 2 */

//     clear: both;
//   }

//   *zoom: 1;
// }

// /**
//  * For IE 6/7 only
//  * Include this rule to trigger hasLayout and contain floats.
//  */

// html {
//   font-size: 16px;
// }

// body {
//   background-color: #333333;
//   font-family: 'Roboto', sans-serif;
//   padding-top: 50px;
// }

// h1 {
//   color: white;
//   text-align: center;
//   font-size: 30px;
//   margin-bottom: 50px;
// }

// h2 {
//   font-weight: 800;
//   text-transform: uppercase;
//   margin-top: 20px;
//   margin-bottom: 20px;
// }

// strong {
//   font-weight: bold;
// }

// #root {
//   max-width: 960px;
//   margin-left: auto;
//   margin-right: auto;
// }

.Collapsible {
  background-color: white;
}

.Collapsible__contentInner {
  padding: 10px;
  border: 1px solid #ebebeb;
  border-top: 0;

  p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  color: #333333;
  position: relative;
  border: 1px solid white;
  padding: 10px;
//   background: #00ac9d;
  background: #48a4f5;
  color: white;

  &:after {
    font-family: 'FontAwesome';
    content: '\f107';
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    transition: transform 300ms;
  }

  &.is-open:after {
    transform: rotateZ(180deg);
  }

  &.is-disabled {
    opacity: 0.5;
    background-color: grey;
  }
}

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease;
}

.CustomTriggerCSS--open {
  background-color: darkslateblue;
}

.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #CBB700;
  color: black;
}