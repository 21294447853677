.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    .form-control, .custom-select, .custom-file {
        position: relative;
        flex: 1 1 auto;
        width: 1%;
        margin-bottom: 0;
    }
}
.input-group-prepend, .input-group-append {
    white-space: nowrap;
    vertical-align: middle;
    display: flex;
}
.input-group-prepend {
    margin-right: -1px;
}
.input-group-text {
    display: flex;
    align-items: center;
    padding: .375rem .75rem;
    margin-bottom: 0;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #3e515b;
    text-align: center;
    white-space: nowrap;
    background-color: #f0f3f5;
    border: 1px solid #c2cfd6;
}

.form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: .875rem;
    line-height: 1.5;
    color: #3e515b;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #c2cfd6;
    border-radius: 0;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.form-control-lg{
    padding: .5rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
}