
	.error{
         margin-bottom: 20px;
         color: #D8000C;
     }

     .r-datapicker{
        border-radius: 4px;
        box-shadow: inset 0 2px 2px #e9e9e9;
        border: 1px solid #aeaeae;
        padding: 6px 10px 5px;
        font-size: 1.09375rem;
        line-height: 1.5;
      
        &:focus {
          outline: none;
          border-color: #aeaeae;
          box-shadow: inset 0 2px 2px #e9e9e9, 0 0 10px 0 rgba(73, 107, 125, 0.3);
        }
     }

//   input:invalid {border: 1px solid red;}
//   input:focus {border:1px solid #1890ff}

  .form-control.invalid {
    border: 1px solid red !important ;
    border-color: 1px solid red !important;

  }

  .required-star{
    color: #D8000C;
  }

  .wr-log{
    top : 40% !important;
  }


//// Language picker css

.flag {
  transition: display .5s;
  opacity: 0.5;
  margin-right: 3px;
}

.flag:hover {
  cursor: pointer;
  opacity: 1;
}

.active {
  transition: all .5s;
  opacity: 1 !important;
}

.new-cus-btn{
  text-align: right;
  padding-top: 5px;
}