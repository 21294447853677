@-webkit-keyframes slide-down {
    0% { opacity: 0; -webkit-transform: translateY(-30%); }
    100% { opacity: 1; -webkit-transform: translateY(0); }
  }
  @-moz-keyframes slide-down {
    0% { opacity: 0; -moz-transform: translateY(-30%); }
    100% { opacity: 1; -moz-transform: translateY(0); }
  }
  @keyframes slide-down {
    0% { opacity: 0; transform: translateY(-30%); }
    100% { opacity: 1; transform: translateY(0); }
  }

  @keyframes lds-hourglass {
    0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)
    }
    50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1)
    }
    100% {
      transform: rotate(1800deg)
    }
  }
  
  @-webkit-keyframes lds-hourglass {
    0% {
      -webkit-transform: rotate(0);
      -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)
    }
    50% {
      -webkit-transform: rotate(900deg);
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1)
    }
    100% {
      -webkit-transform: rotate(1800deg)
    }
  }