.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: 0;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    &.loading{
        i{
            margin: 0 1rem;
        }
    }
}
.btn-primary{
    color: #fff;
    background-color: $primary-color;
    border-color: $primary-color;
    &:focus, .focus {
        box-shadow: 0 0 0 .2rem lighten($primary-color, 7.5%);
        outline: none;
    }
    &:hover{
        background-color: darken($primary-color, 7.5%);
        border-color: darken($primary-color, 7.5%);
    }
}

.btn-danger{
    color: #fff;
    background-color: $danger-color;
    border-color: $danger-color;
    &:focus, .focus {
        box-shadow: 0 0 0 .2rem lighten($danger-color, 7.5%);
        outline: none;
    }
    &:hover{
        background-color: darken($danger-color, 7.5%);
        border-color: darken($danger-color, 7.5%);
    }
}

.btn-success{
    color: #fff;
    background-color: $success-color;
    border-color: $success-color;
    &:focus, .focus {
        box-shadow: 0 0 0 .2rem lighten($success-color, 7.5%);
        outline: none;
    }
    &:hover{
        background-color: darken($success-color, 7.5%);
        border-color: darken($success-color, 7.5%);
    }
}