.fullpage-loader{
    position:fixed;
    display:block;
    width:100%;
    height:100%;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color:rgba(0,0,0,.8);
    z-index:2100
  }
  
  .lds-hourglass:after {
    content: " ";
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: inline-block;
    background: center center no-repeat;
    background-size: cover;
    width: 0;
    height: 0;
    background: none;
    border-radius: 50%;
    border: 70px solid;
    border-color: #fff transparent #fff transparent;
    animation: lds-hourglass 2s linear infinite;
    -webkit-animation: lds-hourglass 2s linear infinite;
  }