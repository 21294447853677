.wrap{
    @include wrap;
    @include wrap("normal");
    @include wrap("gutter", 8);
    .col-half{
        @include col;
        @include col(1, 2);
        margin-bottom: 1rem;
        &.md{
            @media only screen and (max-width: 767px){
                @include col(1, 1);
            }
        }
        &.sm{
            @media only screen and (max-width: 519px){
                @include col(1, 1);
            }
        }
    }
    .col-full{
        @include col;
        @include col(1, 1);
        margin-bottom: 1rem;
    }
}