@import 'variables';
@import 'mixins';
@import 'flexible';
@import 'grid';
@import 'global';
@import 'animations';
@import 'loading';
@import 'button';
@import 'form';
@import 'card';
@import 'tables';
@import 'alerts';
@import 'header';
@import 'left-bar';
@import 'modal';
@import 'not-found';
@import 'common';
@import 'collapsible';
@import 'custom';

.modal{ display:block; }

.sm-title{
    text-align: center;
}

.sm-popup-width{
    max-width: 50%;
}

.action-btn{
    height: 40px;
    width: 125px;
}

.services-popup-close-btn{
    position: absolute;
    margin-left: 95%;
    margin-top: 2%;
    text-align: 'right';
    z-index: 999;
}

.right-align{
    text-align: right;
}

.mlft{
    margin-left: 10px;
}

.mleft-4{
    margin-left: 4px
}

.prices {
    text-align: right;
}