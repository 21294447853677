.left-bar{
    position: fixed;
    z-index: 1019;
    width: 16rem;
    height: calc(100vh - 55px);
    left: 0;
    background: $left-bg;
    transition: left .3s ease-in-out;
    &.open{
        left: 0 !important;
    }
    &.closed{
        left: -16rem !important;
    }
    &.hide-sm{
        @media only screen and (max-width: 767px){
          left: -16rem;
        }
    }
    .content{
        position: relative;
        flex: 1;
        overflow-x: hidden;
        overflow-y: auto;
        ul.side-nav{
            flex-direction: column;
            min-height: 100%;
            display: flex;
            flex-wrap: wrap;
            li{
                a{
                    display: block;
                    padding: .75rem 1rem;
                    color: #fff;
                    text-decoration: none;
                    background: 0 0;
                    &:hover{
                        background-color: $primary-color; 
                        i{
                            color: #fff;
                        }
                    }
                    i{
                        display: inline-block;
                        width: 20px;
                        margin: 0 1rem 0 0;
                        font-size: 1rem;
                        color: #536c79;
                        text-align: center;
                    }
                }
            }
        }
    }
}